<template>
  <!-- 内容 -->
  <div class="investors-box">
    <div class="list-nav">
      <div class="nav-box">
        <router-link class="nav-title" to="/pages/index/index?columnId=452">路演</router-link>
        <span>&gt;</span>
        <a class="nav-title" @click="reloadPage">投资者问答</a>
      </div>
    </div>
    <div class="investors-content">
      <div class="search-box">
        <span>搜索问答</span>
        <!-- <input class="search-input" v-model="selectName" type="text" @keyup.enter="searchList" placeholder="请输入关键字、公司名称或代码" /> -->
        <company-search
          ref="companySearch"
          v-model="selectValue"
          placeholder="请输入关键字、公司名称或代码"
          @itemClick="searchItemClick"
        />
        <a class="search-btn" @click="searchList">搜索</a>
      </div>
      <div class="latesd-qa">
        <h3 class="title">最新问答</h3>
        <div v-if="parseInt(total) > 0">
          <div class="qa-list" v-for="item in replyList" :key="item.id">
            <div class="user-msg">
              <img
                class="avatar"
                :src="`${item.logo}`"
                alt="头像"
                v-if="item.logo && !item.error"
                @error="item.error = true"
              />
              <img
                class="avatar"
                src="~@/assets/img/person/person.svg"
                alt="头像"
                v-else
              />
              <div class="qa-msg">
                <p class="qa-user">
                  <span>{{ item.userName }}</span> 问
                  <span>
                    <template v-if="item.shortName"
                      >{{ item.shortName
                      }}<span v-if="item.stockCode"
                        >（{{ item.stockCode }}）</span
                      ></template
                    >
                    <template v-else>{{ item.businessName }}</template>
                  </span>
                </p>
                <p class="qa-time">{{ item.createTime }}</p>
              </div>
            </div>
            <p class="qa-content">{{ item.content }}</p>
            <div class="reply-box">
              <div
                class="reply-msg"
                v-if="item.replyContent && item.replyContent != ''"
              >
                <p class="reply-user">
                  <span class="reply-user-name" v-if="item.shortName"
                    >{{ item.shortName
                    }}<span v-if="item.stockCode">（{{ item.stockCode }}）</span
                    >回复</span
                  >
                  <span class="reply-user-name" v-else
                    >{{ item.businessName }} 回复</span
                  >
                  <span class="reply-time">{{ item.updateTime }}</span>
                </p>
                <p class="reply-content">{{ item.replyContent }}</p>
              </div>
              <p class="no-reply" v-else>暂无回复</p>
            </div>
          </div>
          <p
            class="loading-more"
            v-if="!finished && !loadMore && pageNo >= 2"
            @click="getMore"
          >
            加载更多
          </p>
          <p class="load-finish" v-if="finished && pageNo > 2">加载完毕</p>
          <p class="loading-data" v-if="loadMore">
            <img src="@/assets/img/article_list/loading.gif" />加载中...
          </p>
        </div>
        <NoData v-else />
      </div>
      <div class="response-rank">
        <h3 class="title">公司回复排行</h3>
        <div class="rank-list" v-if="replyListCompany.length > 0">
          <div
            class="each-list"
            v-for="(item, index) in replyListCompany"
            :key="index"
          >
            <p class="company-img">
              <img
                class="avatar"
                :src="`${item.logo}`"
                alt="头像"
                v-if="item.logo && !item.error"
                @error="item.error = true"
              />
              <img
                class="avatar"
                src="~@/assets/img/person/company.svg"
                alt="头像"
                v-else
              />
              <span>{{ item.name }}</span>
            </p>
            <p class="reply-num">{{ item.count }}条</p>
          </div>
        </div>
        <NoData :picture="false" v-else />
        <!--  && replyList.length -->
        <a
          ref="ask"
          class="ask-btn"
          :class="{ 'ask-btn-fixed': isAskFixed && replyList.length }"
          @click="showAskBox"
        >
          <img src="@/assets/img/detailImg/ask_icon.svg" alt="提问" />
          <span>我要提问</span>
        </a>
      </div>
      <!-- 提问弹框 -->
      <div
        class="ask-question"
        v-show="askFlag"
        @click.prevent="listFlag = false"
      >
        <div class="ask-box">
          <span class="close-ask" @click="cancelAsk"></span>
          <h3 class="ask-title">我要提问</h3>
          <div class="ask-company">
            <span class="enter-kind">选择提问公司</span>
            <div class="enter-company-box">
              <input
                class="enter-company"
                type="text"
                v-model="companyName"
                @click.stop="listFlag = true"
                @focus="companyFocus"
                @blur="companyBlur"
                :placeholder="showCompany ? '' : '请输入公司名或股票代码'"
              />
              <p
                class="show-company"
                :class="companyName || hideCompany ? 'has-value' : ''"
              >
                {{ showCompany }}
              </p>
            </div>
            {{ companyList }}
            <ul
              id="company_list"
              class="company-list"
              v-show="listFlag"
              ref="companyList"
            >
              <li
                v-for="item in companyList"
                :key="item.id"
                @click="chooseCompany(item.id, item.name, item.stockCode)"
              >
                {{ item.name }}{{ item.stockCode }}
              </li>
            </ul>
            <!-- <form class="layui-form select-company" action="" style="font-size:14px">
              <div class="layui-form-item" style="margin: 0">
                <div class="layui-inline" style="margin: 0">
                  <div class="layui-input-block" style="margin: 0">
                    <select name="companyId" lay-verify="required" lay-search="" lay-filter="company" id="company_code">
                      <option :value="item.id" v-for="item in selectList" :key="item.id">{{item.name}}{{item.stockCode}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </form> -->
            <p class="error-tip" v-if="chooseTip">{{ chooseTip }}</p>
          </div>
          <div class="ask-msg">
            <span class="enter-kind marginTop">输入提问信息</span>
            <div class="enter-msg-box">
              <textarea
                class="enter-msg"
                v-model="askContent"
                placeholder="期待您的提问，上市公司将尽快回复您的问题"
                maxlength="200"
              ></textarea>
            </div>
            <p class="error-tip" v-if="errorTip">{{ errorText }}</p>
            <p class="enter-tip">{{ askContent.length }}/200</p>
          </div>
          <div class="opreation-btn">
            <span class="cancel-btn" @click="cancelAsk">取消</span>
            <span class="submit-btn" @click="submitAsk">提交</span>
          </div>
        </div>
      </div>
      <!-- 提交成功弹框 -->
      <div class="model question_model" v-show="submitSuccess">
        <div class="feedback_success">
          <div class="feedback_title">提问成功</div>
          <div class="feedback_success_icon"></div>
          <span class="feedback_success_button" @click="submitSuccess = false">
            关闭
          </span>
        </div>
      </div>
      <!-- <Tips v-if="abnormalTip" :isLoginLink="isLoginLink" :abnormalTxt="abnormalTxt" @closeModel="closeModel"/> -->
    </div>
  </div>
</template>
<script>
import "@/assets/css/investors.less";
import { setCatalogTree } from "@/utils/store-data";
import {
  investorsSearch,
  businessList,
  investorsAdd,
  companySearch,
} from "@/api/investors";
import { getToCUserInfo } from "@/api/login";
import NoData from "@/components/NoData";
import BuryingPont from "@/mixins/buryingPoint.js";
// import Tips from '@/components/Tips'
import CompanySearch from "@/components/companySearch";

export default {
  components: { NoData, CompanySearch },
  mixins: [BuryingPont],
  data() {
    return {
      replyList: [],
      pageNo: 1,
      pageSize: 6,
      replyListCompany: [],
      selectValue: "",
      selectName: "",
      total: 0,
      loadListData: false,
      askFlag: false,
      finished: false,
      loadMore: false,
      loading: false,
      hideCompany: false,
      // isClear: false,
      showCompany: "",
      companyName: "",
      chooseedName: "",
      companyId: "",
      companyList: [],
      // selectList: [],
      listFlag: false,
      askContent: "",
      chooseTip: "",
      errorTip: false,
      errorText: "",
      submitSuccess: false,
      // abnormalTxt: '',
      // abnormalTip: false,
      isAskFixed: false,
      // isLoginLink: false,
      addFlag: false,
      askToTop: 0,
      isAskTop: false,
      companyPageNo: 1,
      companyPageSize: 10,
      totalCompany: 0,
    };
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("scroll", this.scrollFns);
    next();
  },
  watch: {
    companyName() {
      // if(this.isClear){
      // this.listFlag = true
      this.companyPageNo = 1;
      this.companySearch();
      // var newLists = this.companyList.filter(item=>{
      //   return item.stockCode.indexOf(newVal)>=0||item.name.indexOf(newVal)>=0
      // })
      // if(newLists.length>0){
      //   this.companyList = newLists;
      // }else{
      //   this.companyList = [{id:0,name:'暂无数据',stockCode:'',getCode: ''}]
      // }
      // }
    },
    askContent(newVal) {
      if (newVal.length > 0) {
        this.errorText = "";
      }
    },
  },
  created() {
    // window.companySearch = this.companySearch;
    setCatalogTree(this.$store);
    this.investorsSearch();
    this.loadSecond();
    // 回复排行
    businessList({ pageNo: 1, pageSize: 10 })
      .then((res) => {
        res.data.forEach((item) => (item.error = false));
        this.replyListCompany = res.data;
      })
      .catch(() => {});
  },
  mounted() {
    window.addEventListener("scroll", this.scrollFns);
    this.companySearch();
    this.checkScroll();
  },

  methods: {
    // 投资者问答列表及查询接口
    investorsSearch(txt) {
      if (!this.loadListData) {
        this.loadListData = true;
        var listData = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          selectName: this.selectName,
        };
        investorsSearch(listData)
          .then((res) => {
            this.total = res.count;
            res.data.forEach((item) => (item.error = false));
            if (this.pageNo == 1) {
              this.replyList = res.data;
            } else {
              this.replyList = this.replyList.concat(res.data);
            }
            this.loading = false;
            this.loadMore = false;
            // 是否加载完
            if (this.replyList.length >= this.total) {
              this.finished = true;
            }
            this.loadListData = false;
            // 浏览埋点
            this.sensors.track("LY_view_list_page", {
              AppName: this.$store.state.AppName,
              page_id: "",
              total_page:
                res.count % this.pageSize <= 0
                  ? res.count / this.pageSize
                  : parseInt(res.count / this.pageSize) + 1,
              first_level_column: "投资者问答",
              number: res.count,
              is_login: false,
              platform: this.$store.state.platform,
            });
            // 搜索埋点
            if (txt && txt == "search") {
              this.sensors.track("LY_search_result", {
                AppName: this.$store.state.AppName,
                is_login: false,
                has_result: res.count > 0 ? true : false,
                platform: this.$store.state.platform,
                search_keyword: this.selectName,
                search_result_num: res.count,
              });
            }
          })
          .catch(() => {
            this.loadListData = false;
            this.loading = false;
            this.loadMore = false;
          });
      }
    },
    // 2k屏没有滚动条时加载第二页
    loadSecond() {
      var that = this;
      var loadData = setInterval(function() {
        if (!that.finished && !that.loadListData && that.pageNo < 2) {
          if (that.$refs.ask) {
            that.scrollFns();
          } else {
            clearInterval(loadData);
          }
        }
        if (that.pageNo >= 2 || that.finished) {
          clearInterval(loadData);
        }
      }, 100);
    },
    // 加载更多
    getMore() {
      this.pageNo++;
      this.loading = true;
      this.finished = false;
      this.loadMore = true;
      this.investorsSearch();
    },
    // 搜索列表点击
    searchItemClick(item) {
      this.selectName = item.name || item.shortName;
      this.selectValue = this.selectName;
      this.searchList();
      this.$refs.companySearch.showSearchResultList = false;
    },
    // 列表搜索
    searchList() {
      this.selectName = this.selectValue;
      this.pageNo = 1;
      this.loading = false;
      this.finished = false;
      this.loadMore = false;
      this.investorsSearch("search");
      this.loadSecond();
    },
    // 滚动事件
    scrollFns() {
      var scrollH = document.body.scrollHeight;
      var clientH = document.body.clientHeight;
      var toTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (
        scrollH - clientH - toTop < 322 &&
        !this.loading &&
        !this.finished &&
        this.pageNo < 2
      ) {
        this.loading = true;
        this.finished = false;
        this.loadMore = true;
        this.pageNo++;
        this.investorsSearch();
      }

      if (this.$refs.ask.getBoundingClientRect().top == 480 && !this.isAskTop) {
        this.askToTop = toTop;
        this.isAskTop = true;
      }
      if (
        this.$refs.ask.getBoundingClientRect().top <= 480 &&
        this.askToTop <= toTop
      ) {
        this.isAskFixed = true;
      } else {
        this.isAskFixed = false;
      }
    },
    // 显示提问框
    showAskBox() {
      if (this.$store.state.token && this.$store.state.token != "") {
        if (this.$store.state.token.indexOf("BC_") == 0) {
          // this.abnormalTip = true;
          // this.abnormalTxt = '企业用户不可提问，请';
          // this.isLoginLink = true;
          this.$message({
            tipTxt: "企业用户不可提问，请",
            isLink: true,
            loginTxt: "登录个人账户",
          });
        } else {
          getToCUserInfo()
            .then((res) => {
              if (res.code == 0) {
                this.askFlag = true;
                // this.selectCompany();
                // 点击提问按钮埋点
                this.sensors.track("LY_side_nav_click", {
                  AppName: this.$store.state.AppName,
                  is_login: true,
                  click_content: "提问",
                  platform: this.$store.state.platform,
                });
              } else {
                this.$router.push({ path: "/login" });
              }
            })
            .catch(() => {
              this.$router.push({ path: "/login" });
            });
        }
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    // 关闭弹窗
    // closeModel(data){
    //   this.abnormalTxt = data.tipText;
    //   this.isLoginLink = data.linkFlag;
    //   this.abnormalTip = data.tipFlag;
    // },
    // 提问的公司列表
    companySearch() {
      companySearch({
        pageNo: this.companyPageNo,
        pageSize: this.companyPageSize,
        name: this.companyName,
      })
        .then((res) => {
          // var newList = [];
          this.totalCompany = res.count;
          if (this.companyPageNo == 1) {
            this.companyList = [];
          }
          if (res.data && res.data.length > 0) {
            for (var i in res.data) {
              this.companyList.push({
                id: res.data[i].id,
                name: res.data[i].name,
                stockCode: res.data[i].stockCode
                  ? "(" + res.data[i].stockCode + ")"
                  : "",
              });
            }
          } else {
            this.companyList = [{ id: 0, name: "暂无数据", stockCode: "" }];
          }
          // if(this.companyPageNo == 1){
          //   this.companyList = newList;
          //   console.log(newList)
          //   console.log(this.companyList)
          // }else{
          //   this.companyList.concat(newList);
          // }
          // this.selectList = this.companyList;
        })
        .catch(() => {});
    },
    lowEnough() {
      var companyBox = this.$refs.companyList;
      var pageHeight = companyBox.scrollHeight;
      var viewportHeight = companyBox.clientHeight;
      var scrollHeight = companyBox.scrollTop;
      return (
        pageHeight - viewportHeight - scrollHeight < 40 &&
        this.listFlag &&
        this.companyList.length < this.totalCompany
      );
    },
    doSomething() {
      this.companyPageNo++;
      this.companySearch();
      this.pollScroll(); //继续循环
    },
    checkScroll() {
      if (!this.lowEnough()) {
        return this.pollScroll();
      }
      setTimeout(this.doSomething, 100);
    },
    pollScroll() {
      setTimeout(this.checkScroll, 200);
    },
    // 公司获取焦点
    companyFocus() {
      this.hideCompany = true;
      // this.isClear = true;
      // this.companyList = this.selectList;
    },
    // 公司失去焦点
    companyBlur() {
      var that = this;
      this.hideCompany = false;
      // this.isClear = false; // 用于搜索以后点击搜索出来的内容无效果的问题解决方法
      setTimeout(function() {
        that.companyName = "";
      }, 200);
      // if(this.companyId!=''){
      //   for(var i in this.companyList){
      //     if(this.companyList[i].id == this.companyId){
      //       this.showCompany = this.companyList[i].name + this.companyList[i].stockCode
      //     }
      //   }
      // }
    },
    // 选择公司
    chooseCompany(id, name, code) {
      if (id != 0) {
        this.companyId = id;
        this.showCompany = name + code;
        this.chooseedName = name;
        this.listFlag = false;
        this.chooseTip = "";
        this.companyName = "";
      } else {
        this.listFlag = true;
      }
    },
    // layui渲染公司下拉列表及下拉选择选值
    // selectCompany(){
    //   var that = this;
    //   var layui = window.layui;
    //   this.selectList = this.companyList;
    //   // document.getElementById("company_code").value='';
    //   layui.use(['layer', 'jquery', 'form'], function () {
    //     var form = layui.form;
    //     form.on('select(company)', function (data) {
    //       form.render('select');
    //       that.companyId = data.value;
    //       if(that.companyId==''){
    //         that.chooseTip = true;
    //       }else{
    //         that.chooseTip = false;
    //       }
    //     });
    //   });
    // },
    // 取消提问
    cancelAsk() {
      this.askFlag = false;
      this.askContent = "";
      this.chooseTip = "";
      this.errorTip = false;
      // document.getElementById("company_code").value='';
      // var selectLayui = window.layui;
      // var selectForm = selectLayui.form;
      // selectForm.render('select');
      // this.selectList = [];
      this.companyId = "";
      this.companyName = "";
      this.showCompany = "";
      // document.getElementById("company_0").setAttribute("selected","selected");
    },
    // 提交提问
    submitAsk() {
      var askData = {
        businessId: this.companyId,
        content: this.askContent,
      };
      this.chooseTip = "";
      this.errorTip = false;
      if (this.companyId == "") {
        this.chooseTip = "请先选择公司";
      } else if (this.askContent.trim() == "") {
        this.errorTip = true;
        this.errorText = "请输入提问问题";
      } else {
        if (!this.addFlag) {
          this.addFlag = true;
          investorsAdd(askData)
            .then((res) => {
              this.addFlag = false;
              if (res.code == 0) {
                if (!res.data) {
                  this.askFlag = false;
                  this.submitSuccess = true;
                  this.askContent = "";
                  // document.getElementById("company_code").value='';
                  // var layui = window.layui;
                  // var form = layui.form;
                  // form.render('select');
                  // this.selectList = [];
                  this.companyId = "";
                  this.companyName = "";
                  this.showCompany = "";
                  this.backToTop();
                  this.pageNo = 1;
                  this.replyList = [];
                  this.investorsSearch();
                  // var choosed = this.selectList.filter(item=>{
                  //   item.id = askData.businessId
                  // })
                  // 提问提交按钮埋点
                  this.sensors.track("LY_click_submit", {
                    AppName: this.$store.state.AppName,
                    is_login: true,
                    choose_company: this.chooseedName,
                    submit_content: askData.content,
                    platform: this.$store.state.platform,
                  });
                } else {
                  this.errorTip = true;
                  this.errorText = "提问信息包含敏感词";
                }
              } else if (res.code == 3103) {
                this.chooseTip = "企业信息已更新，请重新选择";
                this.addFlag = false;
              } else {
                this.chooseTip = "";
                this.errorTip = false;
                this.askFlag = false;
                this.addFlag = false;
                this.askContent = "";
                // this.abnormalTxt = res.msg;
                // this.abnormalTip = true;
                this.$message({ tipTxt: res.msg, isLink: false });
              }
            })
            .catch((err) => {
              console.log(err);
              this.addFlag = false;
            });
        }
      }
    },
    // 回到顶部
    backToTop() {
      window.scrollTo(0, 0);
      // var timer = setInterval(function(){
      //   let osTop = document.documentElement.scrollTop || document.body.scrollTop;
      //   let ispeed = Math.floor(-osTop / 5);
      //   document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
      //   // this.isTop = true;
      //   if(osTop <= 0){
      //     clearInterval(timer);
      //   }
      // },30)
    },

    reloadPage() {
      this.$router.go(0);
    },
  },
};
</script>
